a:active,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

.thm-black-bg {
  background-color: #252758;
}

.thm-base-bg {
  background-color: #a461e5;
}

.thm-gray-bg {
  background-color: #f2f3f9;
}

.light-text-color {
  color: #fff;
}

.black-text-color {
  color: #252758;
}

.icon-revresed {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: inline-block;
}

.block-title {
  margin-bottom: 60px;
}
.block-title__title {
  margin: 0;
  margin-top: -10px;
  font-size: 60px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.04em;
}

.thm-btn {
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background-color: #f7c324;
  color: #252758;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-radius: 30.5px;
  padding: 13.5px 58px;
  transition: all .4s ease;
}
.thm-btn:hover {
  background-color: #252758;
  color: #fff;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  background-color: transparent;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #a461e5;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  display: none;
  border-radius: 50%;
  transition: all .4s ease;
}
.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
}
.scroll-to-top:hover {
  background-color: #252758;
}
.scroll-to-top:hover i {
  color: #fff;
}

/*
* 6. cta styles
*/

.cta-three {
  /* margin-bottom: -330px; */
  padding-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f2f3f9), to(#fcfdfe));
  background-image: linear-gradient(90deg, #f2f3f9 0%, #fcfdfe 100%);
  padding-top: 250px;
  -webkit-clip-path: polygon(0 0, 100% 31%, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 31%, 100% 100%, 0% 100%);
}
.cta-three__image {
  position: relative;
  overflow: hidden;
  left: -30px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: shapeRotate;
          animation-name: shapeRotate;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.cta-three__image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent url(../../../src/img/cta-shape-1-1-.png) center center no-repeat;
  background-size: 100%;
}
.cta-three__image > img {
  max-width: 100%;
  margin-left: -40px;
}
.cta-three .block-title {
  margin-bottom: 20px;
}
.cta-three__text {
  margin: 0;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: -0.02em;
}
.cta-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 35px;
  margin-bottom: 50px;
}
.cta-three__list-item {
  padding-left: 25px;
  color: #8B8C9C;
  font-size: 16px;
  position: relative;
}
.cta-three__list-item i {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #a461e5;
}
.cta-three__list-item + .cta-three__list-item {
  margin-top: 7px;
}

@-webkit-keyframes shapeRotate {
  0%,
    100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  20%,
    80% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  60% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}

@keyframes shapeRotate {
  0%,
    100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  20%,
    80% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  60% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}
/*
* 13. pricing styles
*/
.pricing-one {
  margin-bottom: -520px;
  position: relative;
}
.pricing-one:before {
  content: '';
  width: 755px;
  height: 755px;
  border: 130px solid #f7c324;
  position: absolute;
  top: -30%;
  right: 85%;
  border-radius: 50%;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.pricing-one__single {
  text-align: center;
  margin-bottom: 40px;
  border-radius: 15px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(37, 39, 88, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(37, 39, 88, 0.1);
  padding: 60px;
  padding-bottom: 50px;
  position: relative;
  z-index: 11;
}
.pricing-one__single.popular .pricing-one__btn {
  background-color: #f7c324;
  border-color: #f7c324;
  color: #252758;
}
.pricing-one__amount {
  margin: 0;
  color: #a461e5;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 5px;
}
.pricing-one__month {
  display: inline-block;
  vertical-align: middle;
  color: #252758;
  text-transform: uppercase;
  letter-spacing: .2em;
  background-color: #f2f3f9;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  padding: 0 20px;
}
.pricing-one__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #eff0f7;
  padding-top: 50px;
  margin-top: 60px;
  position: relative;
}
.pricing-one__list:before {
  content: '';
  width: 81px;
  height: 1px;
  background-color: #a461e5;
  position: absolute;
  top: -1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.pricing-one__list-item {
  color: #8B8C9C;
  font-size: 16px;
}
.pricing-one__list-item + .pricing-one__list-item {
  margin-top: 10px;
}
.pricing-one__btn {
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f7;
  background-color: white;
  color: #8B8C9C;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.02em;
  padding: 12.5px 55.5px;
  margin-bottom: 20px;
  margin-top: 50px;
}
.pricing-one__btn:hover {
  background-color: #f7c324;
  border-color: #f7c324;
  color: #252758;
}
.pricing-one__tag-line {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .2em;
  font-family: 'Montserrat', sans-serif;
  color: #a461e5;
  display: block;
}