footer{
    background-color: #f7c324;
    margin-top:  755px;
}

footer a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
}


footer a:hover {
    color: #f7c324;
}