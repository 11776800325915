.ContactFormWrapper{
    padding-top: 150px;
}

.form-range::-moz-range-thumb{
    background-color: #a461e5;
}

.form-check-input:checked {
    background-color: #a461e5;
    border: #a461e5;
}

.form-control:focus,
.form-select:focus{
    box-shadow: 0 0 0 .25rem rgba(164, 97, 229,.25);
}

.accordion-button:focus{
    box-shadow: none;
}
.form-check .btn{
    /* color: #a461e5; */
    background-color: #f7c324;
}
.accordion-button:not(.collapsed){
    background-color: #fafbfd;
    color:000;
}

.min-120{
    min-height: 120px;
}

.card.card-body{
    width: 300px;
}

.rodo label{
 font-size: 14px;
}

.form-select option,
.form-select{
    font-size: 16px;
    color: #666;
}

.accordion-button:not(.collapsed){
    color: #000;
}