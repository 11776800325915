.pricing-one_choose{
   padding-top: 250px;
}

.pricing-one_choose .pricing-one__single:hover{
    background-color: #ddd;
}
.pricing-one_choose svg:hover{
    transform: scale(1.1);
    transition: 0.2s;
}