@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
  
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: 'Montserrat', sans-serif;
}

*::selection{
  background-color: #f7c324;
  color: #fff;
}
