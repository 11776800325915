header{
    width: 100%;
    height: 100px;
    background-color: #fafbfd;
    position: fixed;
    z-index: 999999999;
    margin-bottom: 80px;
    top: 0;
    left: 0;
}

header img {
    max-height: 60px;
}